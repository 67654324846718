import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import AboutUs from '../components/AboutUs/AboutUs';

const WazaiAboutPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <AboutUs
          imageSrc="/wazai/logo.png"
          imageAlt="Example image"
          title="会社概要"
          subtitle="OUTLINE"
          colorType="secondary"
          sectionId="outline"
          detailItems={[
            {
              title: '会社名',
              description: '株式会社アクティブ・ライフ・サポート',
            },
            {
              title: '運営事業',
              description: '惣菜の製造・販売、デイサービスの運営',
            },
            {
              title: '営業時間',
              description:
                '団らん処-和菜屋：10:30〜18:30 \n デイサービス 和才屋：9:30〜16:00',
            },
            {
              title: '定休日',
              description: '日曜日・祝日',
            },
            {
              title: '所在地',
              description:
                '福岡県北九州市小倉北区金田１丁目１番金田一丁目団地６号棟１０１号室',
            },
            {
              title: 'Email',
              description: 'wazaiya.kokura@gmail.com',
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default WazaiAboutPage;

export const Head = () => (
  <Seo
    title="会社概要"
    description="会社概要をご紹介します。会社名 株式会社アクティブ・ライフ・サポート,惣菜の製造・販売、デイサービスの運営,営業時間団らん処-和菜屋：10:30〜18:30 \n デイサービス 和才屋：9:30〜16:00
  "
  />
);
